import {Link, useLocation, useSearchParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {FormTextField} from '../../ui/forms/input-field/text-field/text-field';
import {Button} from '../../ui/buttons/button';
import {LoginPayload, useLogin} from '../requests/use-login';
import {FormCheckbox} from '../../ui/forms/toggle/checkbox';
import {LinkStyle} from '../../ui/buttons/external-link';
import {SocialAuthSection} from './social-auth-section';
import {AuthLayout} from './auth-layout/auth-layout';
import {Trans} from '../../i18n/trans';
import {StaticPageTitle} from '../../seo/static-page-title';
import {useContext} from 'react';
import {
  SiteConfigContext,
  SiteConfigContextValue,
} from '../../core/settings/site-config-context';
import {useSettings} from '../../core/settings/use-settings';
import {Col, Container, Row} from 'react-bootstrap';
import {useIsDarkMode} from '@common/ui/themes/use-is-dark-mode';
import {useTrans} from '@common/i18n/use-trans';
import {Form} from '@common/ui/forms/form';

interface Props {
  onTwoFactorChallenge: () => void;
}
export function LoginPage({onTwoFactorChallenge}: Props) {
  const [searchParams] = useSearchParams();
  const {pathname} = useLocation();

  const isWorkspaceLogin = pathname.includes('workspace');
  const searchParamsEmail = searchParams.get('email') || undefined;

  const {branding, registration, site, social} = useSettings();
  const siteConfig = useContext(SiteConfigContext);

  const demoDefaults =
    site.demo && !searchParamsEmail ? getDemoFormDefaults(siteConfig) : {};
  const form = useForm<LoginPayload>({
    defaultValues: {remember: true, email: searchParamsEmail, ...demoDefaults},
  });
  const login = useLogin(form);

  const heading = isWorkspaceLogin ? (
    <Trans
      values={{siteName: branding?.site_name}}
      message="To join your team on :siteName, login to your account"
    />
  ) : (
    <Trans message="Sign in to your account" />
  );
  const {trans} = useTrans();

  const isInvalid = !!Object.keys(form.formState.errors).length;

  return (
    <>
      <main className="main-content">
        <div
          className="vh-100"
          style={{
            backgroundImage:
              'url(https://static.vecteezy.com/system/resources/previews/005/585/796/original/abstract-dark-purple-background-overlap-layer-on-dark-space-for-background-design-exclusive-wallpaper-design-for-poster-brochure-presentation-website-etc-vector.jpg)',
            backgroundSize: 'contain',
            backgroundRepeat: 'repeat',
            position: 'relative',
            minHeight: '500px',
          }}
        >
          <Container>
            <Row className="justify-content-center align-items-center height-self-center vh-100">
              <Col lg="5" md="12" className="align-self-center">
                <div className="user-login-card bg-body">
                  <div className="text-center">
                    <Link
                      to="/"
                      className="mb-40 block flex-shrink-0"
                      aria-label={trans({message: 'Go to homepage'})}
                    >
                      <img
                        src={branding.logo_light}
                        className="m-auto block h-42 w-auto"
                        alt=""
                      />
                    </Link>
                  </div>
                  <StaticPageTitle>
                    <Trans message="Login" />
                  </StaticPageTitle>
                  <Form
                    form={form}
                    onSubmit={payload => {
                      login.mutate(payload, {
                        onSuccess: response => {
                          if (response.two_factor) {
                            onTwoFactorChallenge();
                          }
                        },
                      });
                    }}
                  >
                    <FormTextField
                      className="mb-32"
                      name="email"
                      type="email"
                      label={<Trans message="Email" />}
                      disabled={!!searchParamsEmail}
                      invalid={isInvalid}
                      required
                    />
                    <FormTextField
                      className="mb-12"
                      name="password"
                      type="password"
                      label={<Trans message="Password" />}
                      invalid={isInvalid}
                      labelSuffix={
                        <Link
                          className={LinkStyle}
                          to="/forgot-password"
                          tabIndex={-1}
                        >
                          <Trans message="Forgot your password?" />
                        </Link>
                      }
                      required
                    />
                    <FormCheckbox name="remember" className="mb-32 block">
                      <Trans message="Stay signed in for a month" />
                    </FormCheckbox>
                    <Button
                      className="block w-full"
                      type="submit"
                      variant="flat"
                      color="primary"
                      size="md"
                      disabled={login.isPending}
                    >
                      <Trans message="Continue" />
                    </Button>
                  </Form>
                  <div className="fw-500 my-4 flex justify-center gap-2 text-center text-white">
                    <p>
                      <Trans message="Don't have account?" />
                    </p>
                    <Link to="/register">
                      <Trans message="register" />
                    </Link>
                  </div>
                  <div className="seperator d-flex justify-content-center align-items-center">
                    <span className="line"></span>
                    <span className="mx-2">
                      {' '}
                      <Trans message="OR" />
                    </span>
                    <span className="line"></span>
                  </div>
                  <SocialAuthSection />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </main>
    </>
  );
}

function getDemoFormDefaults(siteConfig: SiteConfigContextValue) {
  if (siteConfig.demo.loginPageDefaults === 'randomAccount') {
    // random number between 0 and 100, padded to 3 digits
    const number = Math.floor(Math.random() * 100) + 1;
    const paddedNumber = String(number).padStart(3, '0');
    return {
      email: `admin@demo${paddedNumber}.com`,
      password: 'admin',
    };
  } else {
    return {
      email: siteConfig.demo.email ?? 'admin@admin.com',
      password: siteConfig.demo.password ?? 'admin',
    };
  }
}
