import clsx from 'clsx';
import {CustomMenu} from '../../menus/custom-menu';
import {LocaleSwitcher} from '../../i18n/locale-switcher';
import {DarkModeIcon} from '../../icons/material/DarkMode';
import {LightbulbIcon} from '../../icons/material/Lightbulb';
import {Trans} from '../../i18n/trans';
import {useThemeSelector} from '../themes/theme-selector-context';
import {useSettings} from '../../core/settings/use-settings';
import React, {Fragment, memo} from 'react';
import apple from '../../../../../public/assets/images/footer/apple.webp';
import playstore from '../../../../../public/assets/images/footer/google-play.webp';

//react-bootstrap
import {Button, Col, Container, Form, Row} from 'react-bootstrap';

//react-router-dom
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Logo} from '../navigation/navbar/logo';
import {Button as MainButton} from '../buttons/button';
import {useSelectedLocale} from '@common/i18n/selected-locale';

interface Props {
  className?: string;
  padding?: string;
}

export function Footer({className, padding}: Props) {
  const year = new Date().getFullYear();
  const {branding} = useSettings();
  const {t} = useTranslation();
  const {selectedTheme, selectTheme} = useThemeSelector();
  const {locale} = useSelectedLocale();

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  return (
    <footer>
      <Fragment>
        <footer
          className={`footer footer-default ${selectedTheme.is_dark ? 'border-gray-400 border-t bg-black' : 'bg-white shadow'}`}
        >
          <Container fluid>
            <div className="footer-top">
              <Row className="justify-between">
                <Col xl={3} lg={6} className="mb-lg-0 mb-5">
                  <div className="footer-logo">
                    <Logo
                      logoColor={!selectedTheme.is_dark ? 'dark' : 'light'}
                      isDarkMode={selectedTheme.is_dark}
                    ></Logo>
                  </div>
                  {/* <p className="font-size-14 mb-4">
                    {t('footer.email_us')}:{' '}
                    <span className="text-white">
                      {t('footer.customer@stremit')}
                    </span>
                  </p>
                  <p className="text-uppercase letter-spacing-1 font-size-14 mb-1">
                    {t('footer.customer_services')}
                  </p>
                  <p className="contact mb-0 text-white">+ (480) 555-0103</p> */}
                </Col>
                {/* <Col xl={2} lg={6} className="mb-lg-0 mb-5">
                  <h4 className="footer-link-title">
                    {t('footer.quick_links')}
                  </h4>
                  <ul className="list-unstyled footer-menu">
                    <li className="mb-3">
                      <Link to="./about-us" className="ms-3">
                        {t('header.about_us')}
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link to="/blogs" className="ms-3">
                        {t('header.blog')}
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link to="./pricing" className="ms-3">
                        {t('header.pricing_plan')}
                      </Link>
                    </li>
                    <li>
                      <Link to="./faq" className="ms-3">
                        {t('header.faq')}
                      </Link>
                    </li>
                  </ul>
                </Col>
                <Col xl={2} lg={6} className="mb-lg-0 mb-5">
                  <h4 className="footer-link-title">
                    {t('footer.movies_to_watch')}
                  </h4>
                  <ul className="list-unstyled footer-menu">
                    <li className="mb-3">
                      <Link to="/view-all" className="ms-3">
                        {t('footer.top_trending')}
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link to="/view-all" className="ms-3">
                        {t('footer.recommended')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/view-all" className="ms-3">
                        {t('footer.popular')}
                      </Link>
                    </li>
                  </ul>
                </Col> */}
                {/* <Col xl={2} lg={6} className="mb-lg-0 mb-5">
                  <h4 className="footer-link-title">
                  <Trans message={t('footer.about_company')} />
                  </h4>
                  <ul className="list-unstyled footer-menu">
                    <li className="mb-3">
                      <Link to="/contact-us" className="ms-3">
                        {t('header.contact_us')}
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link to="/PrivacyPolicy" className="ms-3">
                        {t('header.privacy_policy')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms-of-use" className="ms-3">
                        {t('footer.terms_of_use')}
                      </Link>
                    </li>
                  </ul>
                </Col> */}
                <Col xl={3} lg={6} className="mb-lg-0 mb-5">
                  <h4
                    className={clsx(
                      'footer-link-title',
                      selectedTheme.is_dark ? 'text-white' : 'text-black',
                    )}
                  >
                    <Trans message="Follow Us" />
                  </h4>
                  <ul className="widget_social_media m-0 p-0">
                    <li className="">
                      <Link
                        to="https://www.facebook.com/"
                        className="position-relative"
                      >
                        <i className="fab fa-facebook"></i>
                      </Link>
                    </li>{' '}
                    <li className="">
                      <Link
                        to="https://twitter.com/"
                        className="position-relative"
                      >
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>{' '}
                    <li className="">
                      <Link
                        to="https://www.instagram.com/"
                        className="position-relative"
                      >
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://youtube.com/"
                        className="position-relative"
                      >
                        <i className="fab fa-youtube"></i>
                      </Link>
                    </li>{' '}
                  </ul>
                </Col>
                <Col xl={3} lg={6} className="mb-lg-0 mb-5">
                  <div className="font-size-18 font-bold">
                    <Trans message="News Letter" />
                  </div>
                  <div className="mailchimp mailchimp-dark">
                    <div className="input-group mb-3 mt-4">
                      <input
                        type="text"
                        className={clsx(
                          'form-control font-size-14 mb-0',
                          locale.language === 'fa'
                            ? '!rounded-l-none'
                            : '!rounded-r-none',
                          selectedTheme.is_dark
                            ? 'bg-black text-white'
                            : 'bg-white text-black',
                        )}
                        placeholder={t('footer.email')}
                        aria-describedby="button-addon2"
                        style={{border: '1px solid red'}}
                      />
                      <div className="iq-button">
                        <button
                          type="submit"
                          className="btn btn-sm"
                          id="button-addon2"
                        >
                          <Trans message="Subscribe" />
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="footer-bottom border-top flex !w-full flex-wrap !px-20">
              <Row className="align-items-center">
                <ul className="menu list-inline d-flex align-items-center flex-wrap gap-4 p-0">
                  <Menus />
                </ul>
                <Trans
                  message="Copyright © :year :name, All Rights Reserved"
                  values={{year, name: branding.site_name}}
                />
              </Row>
              <div
                className={clsx(
                  ' ml-0 mt-20 flex flex-col items-start gap-10 md:mt-0 md:flex-row md:gap-30',
                  locale.language === 'fa' ? 'md:mr-auto' : 'md:ml-auto',
                )}
              >
                <div>
                  <LocaleSwitcher />
                  <ThemeSwitcher />
                </div>
                <Col>
                  <div className="font-size-18 pb-2 text-center">
                    <Trans message="Download MTDB Apps" />
                  </div>
                  <div className="font-size-16 pb-2 text-center underline">
                    <Trans message="Coming Soon" />
                  </div>
                  <div className="d-flex align-items-center justify-center underline">
                    <div className="d-flex align-items-center">
                      <Link className="app-image" to="#" title="coming soon">
                        <img
                          width={175}
                          src={playstore}
                          loading="lazy"
                          alt="play-store"
                        />
                      </Link>
                      <br />
                      <Link
                        className="app-image ms-3"
                        to="#"
                        title="coming soon"
                      >
                        <img
                          width={150}
                          src={apple}
                          loading="lazy"
                          alt="app-store"
                        />
                      </Link>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </Container>
        </footer>
        <div
          id="back-to-top"
          style={{display: 'none'}}
          // className={animate__animated ${animationClass}}
          onClick={scrollToTop}
        >
          <Link
            className={clsx(
              'btn btn-sm position-fixed top rounded-circle border-0 bg-primary p-0',
              locale.language === 'fa' ? '!left-24' : '!right-24',
            )}
            id="top"
            to="#top"
          >
            <i className="fa-solid fa-chevron-up text-white"></i>
          </Link>
        </div>
      </Fragment>
    </footer>
  );
}

function Menus() {
  const settings = useSettings();
  const primaryMenu = settings.menus.find(m => m.positions?.includes('footer'));
  // const secondaryMenu = settings.menus.find(m =>
  //   m.positions?.includes('footer-secondary'),
  // );

  if (!primaryMenu) return null;

  return (
    <div className="mb-14 items-center justify-between gap-30 overflow-x-auto border-b pb-14 md:flex">
      {primaryMenu && (
        <CustomMenu
          menu={primaryMenu}
          className="flex flex-col !items-start md:flex-row md:!items-center"
          // orientation={window.innerWidth < 768 ? 'vertical' : 'horizontal'}
        />
      )}
      {/* {secondaryMenu && (
        <CustomMenu menu={secondaryMenu} className="mb:mt-0 mt-14 text-muted" />
      )} */}
    </div>
  );
}

function ThemeSwitcher() {
  const {themes} = useSettings();
  const {selectedTheme, selectTheme} = useThemeSelector();
  if (!selectedTheme || !themes?.user_change) return null;

  return (
    <MainButton
      className="max-w-100"
      variant="text"
      startIcon={selectedTheme.is_dark ? <LightbulbIcon /> : <DarkModeIcon />}
      onClick={() => {
        if (selectedTheme.is_dark) {
          selectTheme('light');
        } else {
          selectTheme('dark');
        }
      }}
    >
      {selectedTheme.is_dark ? (
        <Trans message="Light mode" />
      ) : (
        <Trans message="Dark mode" />
      )}
    </MainButton>
  );
}
