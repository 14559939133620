import {ReactElement, ReactNode} from 'react';
import clsx from 'clsx';
import {useAuth} from '@common/auth/use-auth';
import {NotificationDialogTrigger} from '@common/notifications/dialog/notification-dialog-trigger';
import {Menu, MenuTrigger} from '@common/ui/navigation/menu/menu-trigger';
import {useCustomMenu} from '@common/menus/use-custom-menu';
import {createSvgIconFromTree} from '@common/icons/create-svg-icon';
import {Trans} from '@common/i18n/trans';
import {IconButton} from '@common/ui/buttons/icon-button';
import {Item} from '@common/ui/forms/listbox/item';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import {useIsDarkMode} from '@common/ui/themes/use-is-dark-mode';
import {CustomMenu} from '@common/menus/custom-menu';
import {useSettings} from '@common/core/settings/use-settings';
import {ButtonColor} from '@common/ui/buttons/get-shared-button-style';
import {MenuIcon} from '@common/icons/material/Menu';
import {MenuItemConfig} from '@common/core/settings/settings';
import {
  NavbarAuthUser,
  NavbarAuthUserProps,
} from '@common/ui/navigation/navbar/navbar-auth-user';
import {NavbarAuthButtons} from '@common/ui/navigation/navbar/navbar-auth-buttons';
import {useDarkThemeVariables} from '@common/ui/themes/use-dark-theme-variables';
import {Logo} from '@common/ui/navigation/navbar/logo';
import {useLightThemeVariables} from '@common/ui/themes/use-light-theme-variables';
import {isAbsoluteUrl} from '@common/utils/urls/is-absolute-url';
import {customToggle} from '@common/ui/CustomToggle';

import React, {memo, Fragment, useState, useEffect} from 'react';

// react-bootstrap
import {
  Row,
  Col,
  Container,
  Nav,
  Button,
  Dropdown,
  Form,
  Offcanvas,
  Collapse,
  Navbar as NavbarC,
} from 'react-bootstrap';

// react-router-dom
import {Link, useLocation} from 'react-router-dom';

// components
// import Counter from "../../counter";

// the hook
import {useTranslation} from 'react-i18next';
import {useThemeSelector} from '@common/ui/themes/theme-selector-context';
import {useSelectedLocale} from '@common/i18n/selected-locale';

// import user from "/assets/images/user/user1.webp";
// import ott from "/assets/images/mega-menu/new-home.webp";
// import home from "/assets/images/mega-menu/home.webp";
// import movie from "/assets/images/mega-menu/movie.webp";
// import tvshow from "/assets/images/mega-menu/tv-show.webp";
// import video from "/assets/images/mega-menu/video.webp";
// import shop from "/assets/images/mega-menu/shop-home.webp";

type NavbarColor = 'primary' | 'bg' | 'bg-alt' | 'transparent' | string;

export interface NavbarProps {
  hideLogo?: boolean | null;
  toggleButton?: ReactElement;
  children?: ReactNode;
  className?: string;
  color?: NavbarColor;
  bgOpacity?: number | string;
  darkModeColor?: NavbarColor;
  logoColor?: 'dark' | 'light';
  textColor?: string;
  primaryButtonColor?: ButtonColor;
  border?: string;
  size?: 'xs' | 'sm' | 'md';
  rightChildren?: ReactNode;
  menuPosition?: string;
  authMenuItems?: NavbarAuthUserProps['items'];
  alwaysDarkMode?: boolean;
  wrapInContainer?: boolean;
}
export function Navbar(props: NavbarProps) {
  let {
    hideLogo,
    toggleButton,
    children,
    className,
    border,
    size = 'md',
    color,
    textColor,
    darkModeColor,
    rightChildren,
    menuPosition,
    logoColor,
    primaryButtonColor,
    authMenuItems,
    alwaysDarkMode = false,
    wrapInContainer = false,
  } = props;
  const {selectedTheme, selectTheme} = useThemeSelector();
  const isDarkMode = useIsDarkMode() || alwaysDarkMode;
  const {notifications} = useSettings();
  const {isLoggedIn} = useAuth();
  const darkThemeVars = useDarkThemeVariables();
  const lightThemeVars = useLightThemeVariables();
  const showNotifButton = isLoggedIn && notifications?.integrated;
  color = color ?? lightThemeVars?.['--be-navbar-color'] ?? 'primary';
  darkModeColor =
    darkModeColor ?? darkThemeVars?.['--be-navbar-color'] ?? 'bg-alt';

  if (isDarkMode) {
    color = darkModeColor;
  }

  const [isMega, setIsMega] = useState(true);
  const location = useLocation();

  const {locale} = useSelectedLocale();

  //for translation
  const {t} = useTranslation();

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const headerSticky = document.querySelector('.header-sticky');
      if (headerSticky) {
        if (window.scrollY > 1) {
          headerSticky.classList.add('sticky');
        } else {
          headerSticky.classList.remove('sticky');
        }
      }
    };

    const updateIsMega = () => {
      setIsMega(location.pathname === '/');
    };

    window.addEventListener('scroll', handleScroll);
    updateIsMega();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  return (
    <>
      {location.pathname.includes('admin') ? (
        <div
          style={alwaysDarkMode ? darkThemeVars : undefined}
          className={clsx(
            getColorStyle(color, textColor),
            size === 'md' && 'h-100 py-8',
            size === 'sm' && 'h-90 py-4',
            size === 'xs' && 'h-84 py-4',
            border,
            className,
          )}
        >
          <div
            className={clsx(
              'flex h-full items-center justify-end gap-10 pl-14 pr-8 md:pl-20 md:pr-20',
              wrapInContainer && 'container mx-auto',
            )}
          >
            {!hideLogo && (
              <Logo
                isDarkMode={isDarkMode}
                color={color}
                logoColor={logoColor}
              />
            )}
            {toggleButton}
            {children}
            <MobileMenu position={menuPosition} />
            <DesktopMenu position={menuPosition} />
            <div
              className={clsx(
                'flex items-center gap-4 md:gap-14',
                locale.language === 'fa' ? 'mr-auto' : 'ml-auto',
              )}
            >
              {rightChildren}
              {showNotifButton && <NotificationDialogTrigger />}
              {isLoggedIn ? (
                <NavbarAuthUser items={authMenuItems} />
              ) : (
                <NavbarAuthButtons
                  navbarColor={color}
                  primaryButtonColor={primaryButtonColor}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <Fragment>
          <header className="header-center-home header-default header-sticky overflow-x-clip py-2">
            <NavbarC
              expand="xl"
              className="nav navbar-light iq-navbar header-hover-menu py-xl-0"
            >
              <Container fluid className="navbar-inner">
                <div className="d-flex align-items-center justify-content-between landing-header w-100">
                  <div className="d-flex gap-xl-0 align-items-center h-48 gap-3">
                    <div>
                      <Button
                        id="navbar-toggle"
                        bsPrefix="navbar-toggler"
                        type="button"
                        aria-expanded={show1}
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        onClick={() => setShow1(!show1)}
                      >
                        <span className="navbar-toggler-btn">
                          <span className="navbar-toggler-icon"></span>
                        </span>
                      </Button>
                    </div>
                    <Logo
                      isDarkMode={isDarkMode}
                      color={color}
                      logoColor={logoColor}
                    />
                    {toggleButton}
                  </div>
                  {children}
                  <NavbarC
                    expand="xl"
                    className={`offcanvas mobile-offcanvas nav hover-nav horizontal-nav py-xl-0 ${
                      show1 === true ? 'show' : ''
                    } ${isMega ? 'mega-menu-content' : ''}
                    ${!selectedTheme.is_dark || !isDarkMode ? 'bg-white' : ''}
                    `}
                    style={{
                      visibility: `${show1 === true ? 'visible' : 'hidden'}`,
                    }}
                    id="navbar_main"
                  >
                    <Container fluid className="container-fluid p-lg-0">
                      <Offcanvas.Header
                        className="px-0"
                        closeButton
                        onHide={() => setShow1(false)}
                      >
                        <div className="navbar-brand ms-3">
                          <Logo
                            isDarkMode={isDarkMode}
                            color={color}
                            logoColor={selectedTheme.is_dark ? 'dark' : 'light'}
                          />
                        </div>
                      </Offcanvas.Header>
                      <ul
                        className="navbar-nav iq-nav-menu list-unstyled"
                        id="header-menu"
                      >
                        <DesktopMenu position={menuPosition} />
                      </ul>
                      <div>
                        {rightChildren}

                        <ul className="!m-0 flex w-full items-center justify-center gap-2 px-0">
                          {showNotifButton && (
                            <NotificationDialogTrigger
                              onClick={() => setShow1(false)}
                            />
                          )}
                          {!isLoggedIn ? (
                            <NavbarAuthButtons
                              navbarColor={color}
                              primaryButtonColor={primaryButtonColor}
                              onClick={() => setShow1(false)}
                            />
                          ) : (
                            <NavbarAuthUser
                              items={authMenuItems}
                              onClick={() => setShow1(false)}
                            />
                          )}
                        </ul>
                      </div>
                    </Container>
                  </NavbarC>
                  <div className="right-panel lg:block">
                    {/* <Button
                      id="navbar-toggle"
                      bsPrefix="navbar-toggler"
                      type="button"
                      aria-expanded={show}
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      onClick={() => setShow(!show)}
                    >
                      <span className="navbar-toggler-btn">
                        <span className="navbar-toggler-icon"></span>
                      </span>
                    </Button> */}
                    {/* <button
                      id="navbar-toggle"
                      type="button"
                      aria-expanded={show}
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      onClick={() => setShow(!show)}
                      className="block lg:hidden"
                    >
                      <svg
                        width="20px"
                        height="20px"
                        className="icon-20"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
                        ></path>
                      </svg>
                    </button> */}
                  </div>
                </div>
              </Container>
            </NavbarC>
          </header>
        </Fragment>
      )}
    </>
  );
}

interface DesktopMenuProps {
  position: NavbarProps['menuPosition'];
}
function DesktopMenu({position}: DesktopMenuProps) {
  return (
    <CustomMenu
      className="mx-14 text-sm max-[1200px]:flex-col"
      itemClassName={isActive =>
        clsx(
          'opacity-90 hover:underline hover:opacity-100',
          isActive && 'opacity-100',
        )
      }
      iconClassName="mx-2"
      menu={position}
    />
  );
}

interface MobileMenuProps {
  position: NavbarProps['menuPosition'];
}
function MobileMenu({position}: MobileMenuProps) {
  const navigate = useNavigate();
  const menu = useCustomMenu(position);

  if (!menu?.items.length) {
    return null;
  }

  const handleItemClick = (item: MenuItemConfig) => {
    if (isAbsoluteUrl(item.action)) {
      window.open(item.action, item.target)?.focus();
    } else {
      navigate(item.action);
    }
  };

  return (
    <MenuTrigger>
      <IconButton className="md:hidden" aria-label="Toggle menu">
        <MenuIcon />
      </IconButton>
      <Menu>
        {menu.items.map(item => {
          const Icon = item.icon && createSvgIconFromTree(item.icon);
          return (
            <Item
              value={item.action}
              onSelected={() => handleItemClick(item)}
              key={item.id}
              startIcon={Icon && <Icon />}
            >
              <Trans message={item.label} />
            </Item>
          );
        })}
      </Menu>
    </MenuTrigger>
  );
}

function getColorStyle(color: string, textColor?: string): string {
  switch (color) {
    case 'primary':
      return `bg-primary ${textColor || 'text-on-primary'} border-b-primary`;
    case 'bg':
      return `bg ${textColor || 'text-main'} border-b`;
    case 'bg-alt':
      return `bg-alt ${textColor || 'text-main'} border-b`;
    case 'transparent':
      return `bg-transparent ${textColor || 'text-white'}`;
    default:
      return `${color} ${textColor}`;
  }
}
